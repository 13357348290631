import React from "react";
import { Link } from "gatsby";
import BodyClassName from "react-body-classname";
import Header from "../components/header";
import Footer from "../components/footer";
import "../css/juster.css";
import "../css/animation.css";
import Justermov from "../../static/Juster_illus_animate.mp4";
import Poster from "../../static/poster.svg";
import SyncClaims from "../images/ic-sync-claims.svg";
import ScheduleAppointments from "../images/ic-appointment.svg";
import Navigation from "../images/ic-navigate.svg";
import BuildScopingReports from "../images/ic-scoping.svg";
import CaptionPhotos from "../images/ic-photo-captioning.svg";
import TakePhotosVideos from "../images/ic-photos.svg";
import Integrations from "../images/integrations.svg";
import { Helmet } from "react-helmet"

export default () => (
  <>
    <BodyClassName className="home" />
    <Helmet>
      <title>JustEZ | Field Inspection App For Insurance Adjusters</title>
      <meta name="description" content="JustEZ is the last mile app for claim adjusters. It helps to do more inspections, schedule appointments, sync photos to cloud and write scoping reports. Sign up for free - no credit card required" />          
      <meta name="keywords" content="Insurance adjuster, independent insurance adjuster, claim adjuster, final scoping report, field inspection, claim management system, inspection report, inspection assignments" />
    </Helmet>
    <Header></Header>
    <div class="container tp-mainBanner">
      <div class="tp-bannerText">
        <h1>Do. More. Inspections.</h1>
        <p>
          JustEZ is the last mile app for claims adjusters. We crafted JustEZ
          app to serve one purpose - <i>help adjusters do more inspections</i>.
          JustEZ app helps you to sync inspections from various carriers,
          schedule appointments, sync photos to cloud and write scoping reports.
        </p>
        <div class="storeButton">
          <a
            title="AppStore"
            class="btn"
            href="https://itunes.apple.com/us/app/juster-app/id1147082614"
            target="_blank"
          >
            <svg viewBox="0 0 376.5 512">
              <path d="M314.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C59.3 141.2 0 184.8 0 273.5c0 26.2 4.8 53.3 14.4 81.2 12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z" />
            </svg>
            <div>
              Download from
              <em class="appStore">AppStore</em>
            </div>
          </a>
          <a
            title="Google Play"
            class="btn"
            href="https://play.google.com/store/apps/details?id=com.justez"
            target="_blank"
          >
            <svg viewBox="0 0 512 512">
              <path d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z" />
            </svg>
            <div>
              Get it from
              <em class="googlePlay">Google Play</em>
            </div>
          </a>
        </div>
      </div>

      <div class="animation-container">
        <video
          width="100%"
          height="100vh"
          preload="auto"
          poster={Poster}
          loop
          autoPlay
          muted
        >
          <source src={Justermov} type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>
      </div>
    </div>
    <div class="container tp-features">
      <h2>How JustEZ helps you do more…</h2>
      <ul>
        <li>
          <h4>Sync Claims</h4>
          <span class="synClaims">
            <img alt="Sync Claims" title="Sync Claims" src={SyncClaims} />
          </span>
          <p>Sync claims and appointments to your devices in one tap.</p>
        </li>
        <li>
          <h4>Schedule Appointments</h4>
          <span class="appointments">
            <img
              alt="Schedule Appointments"
              title="Schedule Appointments"
              src={ScheduleAppointments}
            />
          </span>
          <p>
            Schedule appointments with your customers while not conflicting with
            your personal agenda.
          </p>
        </li>
        <li>
          <h4>Navigation</h4>
          <span>
            <img alt="Navigation" title="Navigation" src={Navigation} />
          </span>
          <p>
            JustEZ integrates with Waze and Google Maps. Simply drive to the
            loss site while app takes care of directions.
          </p>
        </li>
        <li>
          <h4>Build Scoping Reports</h4>
          <span>
            <img
              alt="Build Scoping Reports"
              title="Build Scoping Reports"
              src={BuildScopingReports}
            />
          </span>
          <p>JustEZ is great tool for building final scoping reports.</p>
        </li>
        <li>
          <h4>Caption Photos</h4>
          <span class="captioning">
            <img
              alt="Caption Photos"
              title="Caption Photos"
              src={CaptionPhotos}
            />
          </span>
          <p>
            Use our AI-based photo captioning technology. It takes 3 minutes to
            caption 80+ photos from the device .
          </p>
        </li>
        <li>
          <h4>Take Photos & Videos</h4>
          <span>
            <img
              alt="Take Photos & Videos"
              title="Take Photos & Videos"
              src={TakePhotosVideos}
            />
          </span>
          <p>
            Now you take photos, videos from your very own camera and import
            them to app without any hassle.{" "}
          </p>
        </li>
      </ul>
      <div class="know_more_ft_text">
        <Link to="/features">Know more about our features</Link>&#10142;
      </div>
    </div>
    <div class="tp-integrations no_bg">
      <div class="container tp-integrations-container">
        <img
          alt="Integrations"
          title="Integrations"
          class="img-responsive"
          src={Integrations}
        />
        <div class="integrations-info">
          <h2>Integrations</h2>
          <p>
            JustEZ integrates with many other apps such as Dropbox, Wi-Fi
            Cameras and Claim management systems; making it easy for you to
            access all apps within a single app only.
          </p>
          <Link class="anc_btn" to="/integrations">
            View all Integrations
          </Link>
        </div>
      </div>
    </div>
    <div class="tp-integrations how_it_works_b">
      <div class="container tp-integrations-container">
        <div class="integrations-info">
          <h2>How it Works</h2>
          <p>
            At JustEZ, our goal is to keep field inspection process simple and
            fun. Also, we are determined to make the experience more effective
            for all the parties involved in it.
          </p>
          <Link class="anc_btn" to="/how-it-works">
            Learn more
          </Link>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </>
);
